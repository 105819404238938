import React, { forwardRef, useMemo } from 'react';
import * as Types from 'types';

import { ActionBar, ArtistCard, useMediaQuery } from '@xceedsrl/jukebox';

import { useAudio } from 'store/audio';

import Link from 'components/CustomLink';

export const ArtistWrapper = forwardRef(function ArtistWrapper({ artist, ...props }, ref) {
  const isMobile = useMediaQuery('mobile');
  const { audioId, stopAudio, toggleAudio } = useAudio();

  const { id, tracks } = artist;
  const isPlaying = id === audioId;

  const actionElement = useMemo(() => {
    return (
      <ActionBar gap={1}>
        {!!tracks?.length && (
          <ActionBar.Music
            tracks={tracks}
            data-id={id}
            width={isMobile ? '22.5px' : '38px'}
            height={isMobile ? '24px' : '40px'}
            isPlaying={isPlaying}
            onClick={toggleAudio}
            onEnd={stopAudio}
          />
        )}
      </ActionBar>
    );
  }, [isPlaying, tracks, isMobile]);

  return <ArtistCard ref={ref} actionElement={actionElement} {...artist} {...props} />;
});

export const LinkedArtist = forwardRef(function LinkedArtistWrapper(props, ref) {
  const {
    artist: { slug, name },
  } = props;

  return (
    <Link
      href="/artist/[...params]"
      as={`/artist/${slug}`}
      aria-label={name}
      id={`artist-wrapper--link-artist-${slug}`}
    >
      <ArtistWrapper ref={ref} {...props} />
    </Link>
  );
});

ArtistWrapper.propTypes = {
  artist: Types.Artist.isRequired,
};

LinkedArtist.propTypes = ArtistWrapper.propTypes;
