import { Artist } from 'models/Artist';
import { Bar } from 'models/Bar';
import { Category } from 'models/Category';
import { Club } from 'models/Club';
import { Event } from 'models/Event';
import { Restaurant } from 'models/Restaurant';
import { getGeoType } from 'utils/getGeoType';

import hosts from '../hosts';
import request from '..';

const cityType = 'cities';

const TYPES = Object.freeze({
  city: cityType,
  country: 'countries',
  artist: 'artists',
  bar: 'bars',
  club: 'clubs',
  event: 'events',
  restaurant: 'restaurants',
  // To be deprecated when the type in category object uses 'club'
  venue: 'clubs',
});

const MODELS = {
  artist: Artist,
  bar: Bar,
  club: Club,
  event: Event,
  restaurant: Restaurant,
  // To be deprecated when the type in category object uses 'club'
  venue: Club,
};

const getHost = geoType => {
  if (geoType !== 'word' && TYPES[geoType] === cityType) return hosts.EVENT_SERVICE;
  return hosts.EVENTS_SERVICE;
};

export class CategoryController {
  static getByType = async ({ type, geo, slug, channel, lang }) => {
    const geoType = getGeoType(geo);
    const host = getHost(geoType);
    const path =
      geoType === 'world'
        ? `/v1/world/${TYPES[type]}/categories/${slug}`
        : `/v1/${TYPES[geoType]}/${geo}/${TYPES[type]}/categories/${slug}`;

    const category = await request({
      path,
      baseUrl: host,
      headers: { 'Accept-Language': lang },
      queryStrings: {
        channel,
      },
    });

    return new Category({ type, ...category });
  };

  static getAll = async ({ geo, channel, lang }) => {
    const geoType = getGeoType(geo);
    const path =
      geoType === 'world' ? '/v1/world/categories' : `/v1/${TYPES[geoType]}/${geo}/categories`;
    const host = getHost(geoType);
    const categories = await request({
      path,
      baseUrl: host,
      headers: { 'Accept-Language': lang },
      queryStrings: {
        channel,
      },
    });

    return categories.map(category => new Category(category));
  };

  static getAllByType = async ({ type, geo, channel, lang }) => {
    const geoType = getGeoType(geo);
    const path =
      geoType === 'world'
        ? `/v1/world/${TYPES[type]}/categories`
        : `/v1/${TYPES[geoType]}/${geo}/${TYPES[type]}/categories`;
    const host = getHost(geoType);
    const categories = await request({
      path,
      baseUrl: host,
      headers: { 'Accept-Language': lang },
      queryStrings: {
        channel,
      },
    });

    return categories.map(category => new Category({ type, ...category }));
  };

  static getContentByType = async ({ type, geo, slug, channel, lang, ...baseParams }) => {
    const geoType = getGeoType(geo);
    const path =
      geoType === 'world'
        ? `/v1/world/${TYPES[type]}/categories/${slug}/${TYPES[type]}`
        : `/v1/${TYPES[geoType]}/${geo}/${TYPES[type]}/categories/${slug}/${TYPES[type]}`;
    const host = getHost(geoType);
    const content = await request({
      path,
      baseUrl: host,
      queryStrings: {
        channel,
        ...baseParams,
      },
      headers: { 'Accept-Language': lang },
    });

    return content.map(item => new MODELS[type](item));
  };
}
